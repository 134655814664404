import React, { useState } from "react";
import "./styles.css";
import leftArrow from "../../../app/assets/icons/leftArrow.svg";
import { Link } from "react-router-dom";

const Navbar = ({ ONCLICK }) => {
  // const [toggleModal, setToggleModal] = useState(false);
  return (
    <>
      <nav className="bg-white border-b border-gray-300 rounded dark:bg-gray-800">
        <div className="px-10 flex flex-wrap justify-between items-center mx-auto">
          <Link to="/recent-uploads">
            <div className="flex items-center pr-5 border-r border-gray-300 h-12">
              <img className="mr-3 hover:cursor-pointer h-4" src={leftArrow} />
              <span className="self-center text-md whitespace-nowrap text-gray-100">
                Uploads
              </span>
            </div>
          </Link>
          <button
            // dataCollapseToggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className="hidden w-full md:block md:w-auto py-2.5"
            id="mobile-menu"
          >
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                <button
                  className="px-7 py-2 rounded-lg bg-dark text-[#fff] border-dark-200"
                  onClick={ONCLICK}
                >
                  Download
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
