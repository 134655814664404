import Dashoard from "./screens/sections/Dashboard";
import RecentUploads from "./screens/sections/RecentUploads";
import SubTitleEditor from "./screens/sections/subtitleEditor";
import UploadVideo from "./screens/sections/uploadVideo";

export const routes = [
  {
    path: "/",
    exact: true,
    component: Dashoard,
  },
  {
    path: "/recent-uploads",
    exact: true,
    component: RecentUploads,
  },
  {
    path: "/upload-video",
    exact: true,
    component: UploadVideo,
  },
  {
    path: "/subtitle-editor",
    exact: true,
    component: SubTitleEditor,
  },
];
