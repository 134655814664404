import React, { useState, useContext, useEffect, useRef } from "react";
import Navbar from "../../components/navbar";
import SubTitle from "../../components/subTitle";
import Add from "../../../app/assets/icons/add.svg";
import Refresh from "../../../app/assets/icons/refresh.svg";
import Editor from "../../components/editor";
import VideoPlayer from "../../components/VedioPlayer";
import Close from "../../../app/assets/icons/close.svg";
import { StoreContext } from "../../../app/stores/root-store";
import { Oval } from "react-loader-spinner";
import Feedback from "../../components/feedback";

const SubTitleEditor = () => {
  const store = useContext(StoreContext);
  const divRef = useRef();

  const [localStateJsonData, setLocalStateJsonDataContent] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [fontFamily, setFontFamily] = useState("");
  const [bold, setBold] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [itelic, setItelic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [positions, setPositions] = useState("bottom");
  const [color, setColor] = useState("");
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [feedback, setFeedbackModal] = useState(false);
  const [embedSubTitles, setEmbedSubTitles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newSubTitles, setNewSubTitles] = useState([]);
  const [updatedVideo, setUpdatedVideo] = useState(null);
  const [resMessage, setResMessage] = useState(null);
  const [timeStampErr, setTimeStampErr] = useState(false);
  const [endTimeStampErr, setEndTimeStampErr] = useState(false);
  const [timeStampErrText, setTimeStampErrText] = useState("");
  const [getIndex, setIndex] = useState(null);
  const [reload, setReload] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});

  let userID = localStorage.getItem("user_id");

  useEffect(() => {
    store.SubtitleContent.loadSubTitlesContent()
      .catch(() => {})
      .finally(() => {
        setLocalStateJsonDataContent(store);
      });
    FetchVideo();
  }, []);

  const FetchVideo = () => {
    setReload(true);
    const video = localStorage.getItem("videoData");
    store?.SubtitleContent?.getVideoDetails(
      userID,
      JSON.parse(video).video_id
    ).then((response) => {
      if (response.status === 200) {
        setReload(false);
        setNewSubTitles(response.data.subtitles);
        setUpdatedVideo(response.data);
      }
    });
  };

  const fontFamilyOpt = [
    {
      value: "AvenirLTStd-BlackOblique",
      label: <p className="font-['AvenirLTStd-BlackOblique']">Avenir Oblique</p>,
    },

    {
      value: "AvenirLTStd-Black",
      label: <p className="font-[AvenirLTStd-Black]">Avenir</p>,
    },

    {
      value: "FuturaStd-BoldOblique",
      label: <p className="font-['FuturaStd-BoldOblique']">Futura Bold Oblique</p>,
    },
    {
      value: "FuturaStd-Bold",
      label: <p className="font['FuturaStd-Bold']">Futura Bold</p>,
    },
    {
      value: "HelveticaLTStd-BlkCondObl",
      label: <p className="font-['HelveticaLTStd-BlkCondObl']">Helvetica Italic</p>,
    },
    {
      value: "HelveticaLTStd-BlkCond",
      label: <p className="font-['HelveticaLTStd-BlkCond']">Helvetica</p>,
    },
    {
      value: "HelveticaNeueLTStd-BdCnO",
      label: <p className="font-['HelveticaNeueLTStd-BdCnO']">Helvetica Neue Italic</p>,
    },
    {
      value: "HelveticaNeueLTStd-BdCn",
      label: <p className="font-['HelveticaNeueLTStd-BdCn']">Helvetica Neue</p>,
    },
    {
      value: "Mont-Black",
      label: <p className="font-['Mont-Black']">Mont</p>,
    },

    {
      value: "Mont-BlackItalic",
      label: <p className="font-['Mont-BlackItalic']">Mont Italic</p>,
    },

    {
      value: "LucidaSansStd-Bold",
      label: <p className="font-['LucidaSansStd-Bold']">LucidaSans Bold</p>,
    },
    {
      value: "LucidaSansStd-BoldItalic",
      label: <p className="font-['LucidaSansStd-BoldItalic']">LucidaSans Italic</p>,
    },
  ];

  const addNewSubtitle = () => {
    const newArr = [...newSubTitles];
    setEndTimeStampErr(false);
    setTimeStampErr(false);
    setTimeStampErrText("");
    setCurrentSelected({});
    const findHeightIndex = Math.max.apply(
      Math,
      newArr.map((s) => s.index)
    );
    const EmdedSubtitles = {
      index: findHeightIndex + 1,
      start_time: "00:00:00.000000",
      end_time: "00:00:00.000000",
      text: "",
    };
    newArr.push(EmdedSubtitles);
    setNewSubTitles(newArr);
  };

  const handleChange = (e, index) => {
    let value = e.target.value;
    const subtitles = [...newSubTitles];
    subtitles[index].text = value;
    setNewSubTitles(subtitles);
  };
  const handleDelete = (index) => {
    const myjsonobj = [...newSubTitles];
    delete myjsonobj.splice(index, 1);
    setNewSubTitles(myjsonobj);
  };

  const handleStartTimeChange = (e, index) => {
    const convert1000 = (time) =>
      time.split(":").reduce((s, t) => s * 60 + +t, 0);
    let value = e.target.value;
    const subtitles = [...newSubTitles];
    const userKeyRegExp = /^[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{6}$/;
    const valid = userKeyRegExp.test(value);
    setCurrentSelected({ ...currentSelected, start_time: value });
    if (!valid) {
      setTimeStampErr(true);
      setIndex(index);
      setTimeStampErrText("");
    } else if (convert1000(value) >= convert1000(currentSelected.end_time)) {
      setTimeStampErr(true);
      setTimeStampErrText("Start time should be less than End time");
    } else {
      setTimeStampErr(false);
      setTimeStampErrText("");
      setEndTimeStampErr(false);
      subtitles[index].start_time = value;
      subtitles[index].end_time = currentSelected.end_time;
      setNewSubTitles(subtitles);
    }
  };

  const handleEndTimeChange = (e, index) => {
    const convert1000 = (time) =>
      time.split(":").reduce((s, t) => s * 60 + +t, 0);
    let value = e.target.value;

    setCurrentSelected({ ...currentSelected, end_time: value });
    const subtitles = [...newSubTitles];
    const userKeyRegExp = /^[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{6}$/;
    const valid = userKeyRegExp.test(value);
    if (!valid) {
      setEndTimeStampErr(true);
      setIndex(index);
      setTimeStampErrText("");
    } else if (convert1000(value) <= convert1000(currentSelected.start_time)) {
      setEndTimeStampErr(true);
      setTimeStampErrText("End time should be greater than Start time");
    } else {
      setTimeStampErr(false);
      setTimeStampErrText("");
      setEndTimeStampErr(false);
      subtitles[index].end_time = value;
      subtitles[index].start_time = currentSelected.start_time;
      setNewSubTitles(subtitles);
    }
  };

  const UpdateSubtitles = () => {
    if (userID && updatedVideo?.video_id && newSubTitles && !timeStampErr) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("user_id", userID);
      formData.append("video", updatedVideo?.video_id);
      formData.append("subtitles", JSON.stringify(newSubTitles));
      setResMessage("");

      store.SubtitleContent.updateSubTitles(formData).then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setUpdatedVideo(response.data.payload);
          setNewSubTitles(response.data.payload.subtitles);
          setResMessage("Subtitles updated successfully!");
          setTimeout(() => setResMessage(""), 2000);
          FetchVideo();
        }
      });
    }
  };

  const handleDownload = () => {
    window.open(
      embedSubTitles?.video && embedSubTitles?.video?.captionated_url,
      "_blank"
    );
  };

  const handleDownloadSrt = () => {
    window.open(
      embedSubTitles?.video && embedSubTitles?.video?.srt_url,
      "_blank"
    );
  };

  const handleEmbedSubtitles = () => {
    const config = {
      font: fontFamily?.value ? fontFamily?.value : "AvenirLTStd-BlackOblique",
      font_colour: color ? color : "#ffffff",
      font_size: parseInt(fontSize),
      stroke_colour: "#000000",
      position: positions?.value ? positions?.value : "bottom",
    };
    let userID = localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("video", updatedVideo && updatedVideo?.video_id);
    formData.append(
      "subtitles",
      JSON.stringify(updatedVideo ? updatedVideo?.subtitles : newSubTitles)
    );
    formData.append("config", JSON.stringify(config));

    setLoaderVisible(true);
    // store.SubtitleContent.getJobStatus(updatedVideo?.video_id).then(
    //   (response) => {
    //     if (response.status === 200) {
    //       console.log(response);
    //     }
    //   }
    // );
    store.SubtitleContent.EmbedSubtitles(formData).then((response) => {
      if (response?.status === 200) {
        setEmbedSubTitles(response?.data);
        setLoaderVisible(false);
      }
    });
  };

  const styles = {
    fontFamily: fontFamily.value ? fontFamily.value : fontFamilyOpt[0].value,
    fontWeight: bold ? "bold" : "",
    fontSize: parseInt(fontSize),
    textDecoration: underline ? "underline" : "none",
    color: color,
    position: positions,
  };

  const handleFocus = (index) => {
    if (currentSelected.index !== index) {
      const current = newSubTitles.find((_, i) => i === index);
      setEndTimeStampErr(false);
      setTimeStampErr(false);
      setTimeStampErrText("");
      setCurrentSelected({ index, ...current });
    }
  };

  const handleBlur = (index) => {
    if (currentSelected.index !== index) {
      // setEndTimeStampErr(false);
      // setTimeStampErr(false);
      // setTimeStampErrText("")
    }
  };

  useEffect(() => {
    let svg = document.querySelector('[data-testid="oval-svg"]');
    svg?.setAttribute('viewBox', '-22.5 -22.5 45 50')
  },[true])

  return (
    <div className={`${toggleModal || feedback ? "bg-gray-300" : ""} h-full`}>
      <div className="m-0">
        <Navbar
          ONCLICK={() => {
            setToggleModal(!toggleModal);
            handleEmbedSubtitles();
          }}
        />
      </div>
      <div className="m-0 flex relative">
        <div className="w-1/4 border-r border-gray-300">
          <div className="mb-4 dark:border-gray-700">
            <ul className="flex flex-wrap justify-around border-b border-gray-300">
              <li className="mr-2 px-4" role="presentation">
                <button
                  className={`inline-block py-4 px-4 text-sm font-medium text-center text-gray-500 rounded-t-lg border-purple hover:border-b-4 border-transparent hover:text-gray-600 hover:border-purple dark:text-gray-400  dark:hover:text-gray-300 ${
                    toggle == false ? "border-b-4 border-purple" : "border-b-1"
                  } ${toggleModal || feedback ? "opacity-20" : "opacity-100"}`}
                  onClick={() => setToggle(false)}
                >
                  Subtitles
                </button>
              </li>
              <li className="mr-2" role="presentation">
                <button
                  className={`inline-block py-4 px-4 text-sm font-medium text-center text-gray-100 rounded-t-lg border-purple hover:border-b-4 border-transparent hover:text-gray-600 hover:border-purple dark:text-gray-400  dark:hover:text-gray-300 ${
                    toggle == true ? "border-b-4 border-purple" : "border-b-1"
                  } ${toggleModal || feedback ? "opacity-20" : "opacity-100"}`}
                  onClick={() => setToggle(true)}
                >
                  Plain Text
                </button>
              </li>
            </ul>
          </div>
          <div className="scrollingContainer">
            {toggle == true && (
              <div className="p-4 bg-gray-50 rounded-lg dark:bg-gray-800 px-8">
                {newSubTitles?.map((item) => {
                  return (
                    <span
                      className={`text-sm text-[#4F4F4F] dark:text-gray-400 lowercase ${
                        toggleModal || feedback ? "opacity-20" : "opacity-100"
                      }`}
                      key={item.index}
                    >
                      {item?.text} /
                    </span>
                  );
                })}
              </div>
            )}
            {toggle == false && (
              <>
                <div
                  className="bg-gray-50 rounded-lg dark:bg-gray-800 px-8 overflow-y-auto"
                  style={{ height: "60vh" }}
                >
                  {newSubTitles?.map((item, i) => {
                    return (
                      <SubTitle
                        index={item.index}
                        paraText={item?.text}
                        from={item?.start_time}
                        to={item?.end_time}
                        bgColor={toggleModal || feedback}
                        handleChange={(e) => handleChange(e, i)}
                        handle_delete={() => handleDelete(i)}
                        changeStartTime={(e) => handleStartTimeChange(e, i)}
                        changeEndTime={(e) => handleEndTimeChange(e, i)}
                        handleFocus={handleFocus}
                        timeStampErr={timeStampErr}
                        endTimeStampErr={endTimeStampErr}
                        timeStampErrText={timeStampErrText}
                        selectedIndex={getIndex}
                        currentSelected={currentSelected}
                        handleBlur={handleBlur}
                      />
                    );
                  })}
                  <div
                    className={`${
                      toggleModal || feedback
                        ? "opacity-5 bg-gray-300"
                        : "bg-gray-300"
                    } bigDiv`}
                    ref={divRef}
                  ></div>
                </div>
                <div className="flex justify-center flex-col px-5 border-t border-gray-300 py-5">
                  <button
                    className="flex justify-center px-7 w-8/12 mx-auto py-2 my-2 rounded-lg border border-gray-200"
                    onClick={() => {
                      addNewSubtitle();
                      divRef.current.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <img src={Add} />
                    Add Subtitles
                  </button>
                  <button
                    className="flex justify-center px-7 py-2 w-8/12 my-2 mx-auto rounded-lg bg-dark text-[#fff] border-dark-200"
                    onClick={UpdateSubtitles}
                  >
                    {isLoading ? (
                      <div>
                        <svg
                          role="status"
                          class="inline mr-3 w-4 h-4 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                        Loading...
                      </div>
                    ) : (
                      <div className="flex">
                        <img src={Refresh} className="mx-1 w-8 h-6" />
                        Update Subtitles
                      </div>
                    )}
                  </button>
                  <p className="text-[#236c4e] text-center py-2">
                    {resMessage}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-3/4">
          <Editor
            fontFamilyToggle={(value) => setFontFamily(value)}
            handleBold={() => setBold(!bold)}
            handleFontSize={(e) => setFontSize(e.target.value)}
            handleItelic={() => setItelic(!itelic)}
            handleUnderline={() => setUnderline(!underline)}
            handlePosition={(value) => setPositions(value)}
            handleColorChange={(e) => setColor(e.target.value)}
            fontFamilyOpt={fontFamilyOpt}
            styles={{
              bold: bold,
              fontFamily: fontFamily,
              fontSize: fontSize,
              underline: underline,
              color: color,
              itelic: itelic,
              positions: positions,
            }}
            fontSize={fontSize}
            feedback={feedback || toggleModal}
          />
          <div className="my-2">
            {!reload && newSubTitles ? (
              <VideoPlayer
                src={updatedVideo?.video_url}
                subtitles={newSubTitles}
                styles={styles}
                thumbnail={updatedVideo?.thumbnail_url}
                subtitle_url={updatedVideo?.vtt_file}
              />
            ) : (
              <div className="flex justify-center items-center h-96 w-full">
                <Oval
                  color="#1E262C"
                  secondaryColor="#DBDBDB"
                  height={40}
                  width={50}
                  strokeWidth={5}
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
        <button
          className="absolute right-10 bottom-10 text-[#0056b3]"
          onClick={() => setFeedbackModal(true)}
        >
          Got feedback?
        </button>
        {/* popup Modal */}
        <div
          id="authentication-modal"
          className={`${
            feedback == true ? "visible" : "hidden"
          } overflow-hidden fixed left-52 top-4 z-50 flex justify-center items-center h-modal md:h-full md:inset-0`}
        >
          <div
            className={`flex justify-center items-center bg-[#fff] rounded-lg`}
          >
            <div
              className={`relative px-4 w-full max-w-3xl py-9 md:h-auto mx-auto rounded-lg shadow-xl dark:bg-gray-700 w-96`}
            >
              <div className="flex justify-end items-center p-2">
                <p className="text-[#1E262C] text-lg">Leave a feedback</p>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={() => setFeedbackModal(false)}
                >
                  <img src={Close} />
                </button>
              </div>
              <div>
                <Feedback />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* popup modal */}
      <div
        id="authentication-modal"
        aria-hidden="true"
        className={`${
          toggleModal == true ? "visible" : "hidden"
        } overflow-hidden fixed mr-16 mt-16 z-50 flex justify-end h-modal md:h-full md:inset-0`}
      >
        <div className="overflow-hidden relative px-4 h-5/6  w-full max-w-sm py-5 bg-[#fff] bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-end items-center p-2">
            <p className="text-[#1E262C] text-lg">Export Options</p>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setToggleModal(!toggleModal)}
            >
              <img src={Close} />
            </button>
          </div>
          <div className="w-full h-80">
            <div className="relative h-64">
              <video
                className="mx-auto rounded-xl object-fill"
                poster={updatedVideo?.thumbnail_url}
                src={updatedVideo?.video_url}
                // controls
              />
              <div className="relative w-full border-[red] bottom-14 overflow-hidden">
                <div className="bg-[#000] flex justify-between items-center h-14 rounded-b-lg px-3">
                  <p className="text-[#fff]">{updatedVideo?.video_id}</p>
                </div>
              </div>
            </div>
            <div className="max-h-14"></div>
            <div>
              <div className="flex justify-center items-center flex-col h-8 max-w-sm">
                <Oval
                  color="#1E262C"
                  secondaryColor="#DBDBDB"
                  height={40}
                  width={50}
                  strokeWidth={5}
                  visible={loaderVisible}
                />
                {loaderVisible && <span>Exporting Video...</span>}
                {loaderVisible && <span>This might take a few minutes depending on the size of your video</span>}

              </div>
            </div>
            {!loaderVisible && embedSubTitles?.video ? (
              <div>
                <button
                  className="px-7 mt-8 w-full py-3 my-5 rounded-lg bg-dark text-[#fff] border-dark-200"
                  onClick={handleDownload}
                >
                  Download Video
                </button>
                <button
                  className="px-7 py-3 w-full rounded-lg border-x border-y border-gray-200"
                  onClick={handleDownloadSrt}
                >
                  Download VTT
                </button>
              </div>
            ) : (
              <p
                className={`text-[red] ${
                  !loaderVisible && !embedSubTitles?.video
                    ? "visible"
                    : "hidden"
                }`}
              >
                Somthing went wrong, Please try again
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubTitleEditor;
