import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../../app/assets/images/cap-logo.svg";
import DashImg from "../../../app/assets/images/dash-img.jpg";
import Close from "../../../app/assets/icons/close.svg";
import Feedback from "../../components/feedback";
import { v4 as uuidv4 } from "uuid";

const Dashoard = () => {
  const [feedback, setFeedbackModal] = useState(false);

  return (
    <div className={`${feedback == true ? "bg-gray-100" : "bg-[#fff]"} `}>
      <div className="h-screen overflow-hidden container mx-auto relative">
        <div className="flex justify-start items-center h-24">
          <Link to="/">
            <img src={Logo} />
          </Link>
        </div>
        <div className="grid gap-4">
          <div className="flex justify-between items-start xl:flex-nowrap lg:flex-wrap md:flex-wrap sm:flex-wrap">
            <div className="flex-1 mt-28">
              <h1 className="text-4xl font-bold leading-relaxed font-Manrope">
                Automatically generate and embed subtitles in your videos
              </h1>
              <p className="text-lg leading-6 text-[#3D3D3D] font-Manrope py-4">
                Transcribe, edit and embed subtitles for FREE with Captionate.
              </p>
              <Link to="/upload-video">
                <button
                  className="w-52 h-16 rounded-lg bg-dark text-[#fff] border-dark-200 mt-5"
                  onClick={localStorage.setItem("user_id", uuidv4())}
                >
                  Get Started
                </button>
              </Link>
            </div>
            <div
              className={`flex-2 lg:flex-2 md:flex-1 mt-14 ${
                feedback == true ? "opacity-20" : "opacity-100"
              }`}
            >
              <img src={DashImg} width={"100%"} height={"800"} />
            </div>
          </div>
        </div>
        <button
          className="absolute right-0 bottom-10 text-[#0056b3]"
          onClick={() => setFeedbackModal(true)}
        >
          Got feedback?
        </button>
        {/* popup Modal */}
        <div
          id="authentication-modal"
          className={`${
            feedback == true ? "visible" : "hidden"
          } overflow-hidden fixed left-52 top-4 z-50 flex justify-center items-center h-modal md:h-full md:inset-0`}
        >
          <div
            className={`flex justify-center items-center bg-[#fff] rounded-lg`}
          >
            <div
              className={`relative px-4 w-full max-w-3xl py-9 md:h-auto mx-auto rounded-lg shadow-xl dark:bg-gray-700 w-96`}
            >
              <div className="flex justify-end items-center p-2">
                <p className="text-[#1E262C] text-lg">Leave a feedback</p>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={() => setFeedbackModal(false)}
                >
                  <img src={Close} />
                </button>
              </div>
              <div>
                <Feedback />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashoard;
