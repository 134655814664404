import { observable, action, makeObservable } from "mobx";
import SubtitlesContent from "../api/data/subtitle-content.json";
import axios from "axios";
import URL from "../api/urls";

const BASE_URL = "https://captionate-314819.uc.r.appspot.com";

export class SubtitleEditorStore {
  // parent = null;
  subtitleContent = [];
  subTitles = [];
  constructor() {
    makeObservable(this, {
      subtitleContent: observable,
      loadSubTitlesContent: action,
    });
  }

  async loadSubTitlesContent() {
    return (this.subtitleContent = SubtitlesContent);
  }

  async getJobStatus(id) {
    const response = await axios.get(
      BASE_URL + URL.GET_JOB_STATUS + `?id=${id}`
    );
    return response;
  }

  async updateSubTitles(data) {
    const response = await axios.post(
      BASE_URL + URL.UPDATE_SUBTITLES, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  }

  async EmbedSubtitles(data) {

    const response = await axios.post(BASE_URL + URL.EMDEDSUBTITLES, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }

  async handleFeedback (data) {
    const response = await axios.post(BASE_URL + URL.FEEDBACK, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response;
  }

  async addSubtitles(data) {
    const item = {
      ...data,
    };
    this.subTitles.push(item);
  }

  async getVideoDetails(userID, video_id) {
    const response = await axios.get(BASE_URL + URL.FETCH_VIDEO_DETAILS + `?user_id=${userID}&video_id=${video_id}` );
    return response;
  }

}


