import { observable, action, makeObservable } from "mobx";
import UploadVideoContent from "../api/data/uploadVideo.json";
import axios from "axios";
import URLS from "../api/urls";

const BASE_URL = "https://captionate-314819.uc.r.appspot.com";

export class UploadVideoStore {
  uploadVideoContent = [];
  constructor() {
    makeObservable(this, {
      uploadVideoContent: observable,
      loadUploadVideoContent: action,
    });
  }
  async loadUploadVideoContent() {
    return (this.uploadVideoContent = UploadVideoContent);
  }

  async upload_video(video_file,config) {
    return this.generate_upload_url(video_file).then((response) => {
      if (response?.status === 200) {
        const signed_url = response?.data.video_upload_url;
        const video_id = response?.data.video_id;
         return this.upload_file_to_GCS(video_file,signed_url,config).then((response) => {
          if (response.status === 200) {
            return this.set_video_success(video_id)
          } else {
            alert("Something went wrong")
          }
        });
      } else {
        alert("Something went wrong")
      }
    })
  }

  async set_video_success(video_id) {
    let userID = localStorage.getItem('user_id')
    const formData = new FormData();
    formData.append("video_id", video_id);
    formData.append('user_id', userID)

    const response = await axios.post(
      BASE_URL + URLS.VIDEO_UPLOAD_SUCCESS,
      formData,
    );
    return response;
  }

  async generate_upload_url(fileData) {
    const response = await axios.get(
      BASE_URL + URLS.GENERATE_UPLOAD_URL + `?file_name=${fileData.name}`
    );
    return response;
  }

  async upload_file_to_GCS(video_file, url, config) {
    const response = await axios.put(url,video_file, config);
    return response;
  }
  
  async uploadVideo(fileData, options) {
    let userID = localStorage.getItem('user_id')
    const formData = new FormData();
    formData.append("file", fileData);
    formData.append('user_id', userID)

    this.uploadPercentage = 0;

    const response = await axios.post(
      BASE_URL + URLS.UPLOAD_VIDEO,
      formData,
      options,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  }
  async Generate_subtitles(userID, video, language, ) {
    const response = await axios.post(
      BASE_URL +
        URLS.GERNERATE_SUBTITLES + `?user_id=${userID}&video=${video}&language=${language}`
    );
    return response;
  }
}