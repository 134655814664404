import React, { useState, useEffect, useContext } from "react";
import Logo from "../../../app/assets/images/cap-logo.svg";
import { Link } from "react-router-dom";
import { StoreContext } from "../../../app/stores/root-store";
import UploadVideoPopup from "../../components/uploadVideoPopup";
import Unsplash from "../../../app/assets/images/unsplash.jpg";
import Close from "../../../app/assets/icons/close.svg";
import { useHistory } from "react-router-dom";
import Feedback from "../../components/feedback";

const UploadVideo = () => {
  const store = useContext(StoreContext);
  const [localStateJsonData, setLocalStateJsonDataContent] = useState(null);
  const [upload, setUpload] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [browseFile, setBrowseFile] = useState("");
  const [language, setLanguage] = useState("");
  const [subtitleCOntent, setSubtitleContent] = useState("");
  const [video, setVideo] = useState("");
  const [progress, setProgress] = useState(0);
  const [subtitles, setSubtitles] = useState("");
  const [err, setErr] = useState("");
  const [subtitleErr, setSubtitleErr] = useState("");
  const [toggleModal, setToggleModal] = useState(false);
  const [feedback, setFeedbackModal] = useState(false);
  const [userID, setUserID] = useState(null)

  useEffect(() => {
    store.UploadVideo.loadUploadVideoContent()
      .catch(() => {})
      .finally(() => {
        setLocalStateJsonDataContent(store);
      });
  }, []);

  const history = useHistory();

  const handleBrowse = (e) => {
    setBrowseFile(e.target.files[0]);
    if (!e.target.files[0]) {
      setErr("Please browse the file!");
    } else if (e.target.files[0]?.size > 1024 * 1024 * 100) {
      setErr("Video file should be less than 100 MB");
    } else if (
      e.target.files[0] &&
      e.target.files[0]?.size < 1024 * 1024 * 101
    ) 
    {

      setErr("");
      const video_file = e.target.files[0]
      const config = {
        headers: {
          'content-type': video_file.type,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      };
      store.UploadVideo.upload_video(video_file,config).then((response) => {
        if (response?.status === 200) {
          setVideo(response?.data);
          setUserID(response?.data?.user_id);
          }
        else {
          alert("Something went wrong")
        }
      })
    }
  };
  const handleModal = () => {
    setToggleModal(false);
    setConfirmModal(false);
    setBrowseFile("");
    setUpload("");
    setProgress(0);
    window.location.reload();
  };

  const handleSubtitles = () => {
    if (!upload && !language) {
      setSubtitleErr("Please upload video and select language!");
    } else {
      setSubtitleErr("");
      setLoaderVisible(true);
      store.UploadVideo.Generate_subtitles(
        video?.user_id,
        video?.video_id,
        language?.value
      ).then((response) => {
        setSubtitleContent(response?.data);
        setSubtitles(response?.subtitles);
        if (response?.status === 200) {
          setLoaderVisible(false);
        }
        const data = {
          id: response?.data?.video_id,
          source: video,
          poster: Unsplash,
          status: response?.data?.status,
        };
        addRecentUploadedVideos(data);
        history.push("./recent-uploads");
        let userID = localStorage.getItem("user_id");
        store.RecentUploads.getRecentUploadedVideos(video?.user_id).then((res) => {
        });
      });
    }
  };

  const addRecentUploadedVideos = (data) => {
    store?.RecentUploads?.addRecentUploads(data);
  };

  const handleDelete = () => {
    setUpload("");
    setBrowseFile("");
    window.location.reload();
  };

  return (
    <>
      <div
        className={`${
          toggleModal || feedback == true ? "bg-gray-100" : "bg-[#fff]"
        } relative`}
      >
        <div className="h-screen container mx-auto">
          <div className="flex justify-start items-center h-24">
          <Link to="/" className="logo">
            <img src={Logo} />
          </Link>
          </div>
          <div className="overflow-hidden fixed left-52 top-4 z-50 flex justify-center items-center h-modal md:h-full md:inset-0">
            <UploadVideoPopup
              toggleConfirmCodal={() => setConfirmModal(!confirmModal)}
              confirmModal={confirmModal}
              uploadedVideo={video}
              defaultValue={language?.value}
              language={
                localStateJsonData?.UploadVideo?.uploadVideoContent?.languageOpt
              }
              handleBrowse={handleBrowse}
              browseFile={browseFile}
              generateSubtitles={handleSubtitles}
              loader={loaderVisible}
              handleLangChange={(value) => setLanguage(value)}
              progress={progress}
              handleDelete={handleDelete}
              closeConfirmModal={handleModal}
              error={err}
              subtitleErr={subtitleErr}
              feedBackPopup={feedback}
            />
            <button
              className="absolute right-14 bottom-10 text-[#0056b3]"
              onClick={() => setFeedbackModal(true)}
            >
              Got feedback?
            </button>
          </div>
        </div>
      </div>
      {/* popup Modal */}
      <div
        id="authentication-modal"
        className={`${
          feedback == true ? "visible" : "hidden"
        } overflow-hidden fixed left-52 top-4 z-50 flex justify-center items-center h-modal md:h-full md:inset-0`}
      >
        <div
          className={`flex justify-center items-center bg-[#fff] rounded-lg`}
        >
          <div
            className={`relative px-4 w-full max-w-3xl py-9 md:h-auto mx-auto rounded-lg shadow-xl dark:bg-gray-700 w-96`}
          >
            <div className="flex justify-end items-center p-2">
              <p className="text-[#1E262C] text-lg">Leave a feedback</p>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setFeedbackModal(false);
                }}
              >
                <img src={Close} />
              </button>
            </div>
            <div>
              <Feedback />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadVideo;
