// import { observable, action, decorate } from "mobx";
import RecentUploadsContent from "../api/data/recentUploads.json";
import axios from "axios";
import URLS from "../api/urls";

import { action, makeObservable, observable } from "mobx";

const BASE_URL = "https://captionate-314819.uc.r.appspot.com";

export class RecentUploadsStore {
  recentUploads = [];
  recentUploaded = [];

  constructor() {
    makeObservable(this, {
      recentUploads: observable,
      recentUploads: observable,
      loadRecentUploads: action,
      uploadVideo: action,
      addRecentUploads: action,
    });
  }

  async loadRecentUploads() {
    return (this.recentUploads = RecentUploadsContent);
  }

  async uploadVideo(fileData) {
    const formData = new FormData();
    formData.append("file", fileData);

    this.uploadPercentage = 0;

    const response = await axios.post(BASE_URL + URLS.UPLOAD_VIDEO, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        this.uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }.bind(this),
    });
    return response;
  }

  async getRecentUploadedVideos(userID) {
    const response = await axios.get(BASE_URL + URLS.FETCH_ALL_VIDEOS + "?user_id=" + userID);
    return response
  }

  addRecentUploads(recentUpload) {
    const item = {
      ...recentUpload,
    };
    this.recentUploaded.push(item);
  }
}
