import React, { useEffect, useState } from "react";
import Close from "../../../app/assets/icons/close.svg";
import Export from "../../../app/assets/icons/export.svg";
import VideoSquare from "../../../app/assets/icons/video-square.svg";
import Progress from "react-progressbar";
import Trash from "../../../app/assets/icons/trash.svg";
import Select from "react-select";
import { Oval } from "react-loader-spinner";
import "./styles.css";

const UploadVideoPopup = ({
  toggleConfirmCodal,
  confirmModal,
  uploadedVideo,
  language,
  handleBrowse,
  browseFile,
  closeConfirmModal,
  defaultValue,
  generateSubtitles,
  loader,
  handleLangChange,
  progress,
  handleDelete,
  error,
  subtitleErr,
  feedBackPopup,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 20,
    }),
    control: () => ({
      border: "1px solid #DBDBDB",
      borderRadius: "8px",
      display: "flex",
      width: 330,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    let svg = document.querySelector('[data-testid="oval-svg"]');
    svg?.setAttribute('viewBox', '-22.5 -22.5 45 50')
  },[loader])

  return (
    <div
      className={`${
        confirmModal == true
          ? "bg-gray-100"
          : feedBackPopup
          ? "opacity-20"
          : "bg-[#fff] opacity-100"
      } flex justify-center items-center bg-[#fff] rounded-lg`}
    >
      <div
        className={`relative px-4 w-full max-w-3xl py-9 md:h-auto mx-auto rounded-lg video-popup dark:bg-gray-700`}
      >
        <div className="flex justify-end items-center">
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            onClick={toggleConfirmCodal}
          >
            <img src={Close} />
          </button>
        </div>
        <p className="text-[#1E262C] text-lg ml-32">Upload Video File</p>
        <div className="flex mt-5 h-80">
          <div
            className={`${
              uploadedVideo ? "hidden" : "visible"
            } flex-1 w-2/4 border border-gray-300 rounded-lg mx-5 py-5`}
          >
            <div className="border-2 mx-auto border-dotted w-64 h-44 flex justify-center items-center rounded-xl border-[#7B61FF]">
              <label htmlFor="upload-photo" className="w-full">
                <div>
                  <div
                    className={`${
                      browseFile ? "hidden" : ""
                    } flex flex-col items-center w-100`}
                  >
                    <img src={Export} className="mx-auto" />
                    <span className="text-gray-100">
                      Drag and Drop here
                    </span>{" "}
                    <span className="text-gray-100">Or</span>{" "}
                    <span>Browse</span>
                  </div>
                  <div className="flex justify-center">
                    {uploadedVideo?.video_id && <img src={VideoSquare} />}
                    {uploadedVideo?.video_id}
                  </div>
                </div>
                <div className={`${browseFile?.name ? "visible" : "hidden"} w-full`}>
                  <img src={Export} className="mx-auto" />
                  <p className="text-center">{browseFile?.name}</p>
                  <Progress
                    className="w-11/12 rounded-lg h-8 bg-[#E5E5E5] mx-auto mt-3"
                    completed={progress}
                  />
                </div>
              </label>
              <input
                type="file"
                name="photo"
                id="upload-photo"
                onChange={handleBrowse}
              />
            </div>
            <div
              className="px-7 mt-8 mx-12 w-64 py-3 my-5 rounded-lg text-center bg-dark text-[#fff] border-dark-200"
              // onClick={handleUpload}
            >
              <label htmlFor="upload-photo" className="w-full">
                Upload
              </label>
              <input
                type="file"
                name="photo"
                id="upload-photo"
                onChange={handleBrowse}
              />
            </div>
            <div className="flex items-center justify-center">
              {error ? (
                <small className="text-[#fb4128] text-center">{error}</small>
              ) : null}
            </div>
          </div>
          {uploadedVideo && (
            <div className="relative h-64 rounded-xl mx-5">
              <video
                className="rounded-xl h-full w-full object-fill"
                poster={uploadedVideo?.thumbnail_url}
                src={uploadedVideo?.video_url}
                // controls
              />
              <div className="relative h-full w-full border-[red] bottom-14 overflow-hidden">
                <div className="bg-[#000] flex justify-between items-center h-14 rounded-b-lg px-3">
                  <p className="text-[#fff]">{uploadedVideo?.video_id}</p>
                  <button
                    className="hover:cursor-pointer"
                    onClick={handleDelete}
                  ></button>
                </div>
              </div>
            </div>
          )}
          <div className="flex-1 w-96">
            {!loader ? (
              <div className="h-64">
                <label htmlFor="select-lang" className="text-[#1E262C] text-md">
                  Select Language:
                </label>
                <Select
                  id="select-lang"
                  className="flex justify-between outline-0 mt-2"
                  defaultValue={defaultValue}
                  onChange={handleLangChange}
                  options={language}
                  placeholder="Select language"
                  styles={customStyles}
                />
              </div>
            ) : (
              <div className="flex justify-center items-center flex-col h-52 max-w-4xl">
                <Oval
                  ariaLabel="loading-indicator"
                  color="#1E262C"
                  secondaryColor="#DBDBDB"
                  height={90}
                  width={100}
                  strokeWidth={5}
                  visible={loader}
                />
                <span>Generating subtitles...</span>
              </div>
            )}
            {loader === false ? (
              <button
                className={`${
                  uploadedVideo?.video_url && defaultValue
                    ? "bg-[#000]"
                    : "bg-[#C4C8CB] disable"
                } rounded-lg  px-12 py-3 w-full text-[#fff] border-dark-200`}
                onClick={generateSubtitles}
              >
                Generate Subtitles
              </button>
            ) : (
              <button
                className="px-7 py-3 w-full w-80 mx-auto rounded-lg border-x border-y border-gray-200"
                onClick={(toggleConfirmCodal, closeConfirmModal)}
              >
                Cancel
              </button>
            )}
            <div className="flex items-center justify-center">
              {subtitleErr ? (
                <small className="text-[#fb4128] text-center">
                  {subtitleErr}
                </small>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`${
            confirmModal == true ? "visible" : "hidden"
          } bg-[#fff] rounded-lg px-10 py-6 absolute top-44 left-56`}
        >
          <div>
            <h2>Are you sure you want to cancel this upload?</h2>
            <div className="flex justify-around mt-5">
              <button
                className="w-28 h-10 rounded-lg border-x border-y border-gray-200"
                onClick={toggleConfirmCodal}
              >
                Close
              </button>
              <button
                className="w-28 h-10 rounded-lg text-[#fff] bg-[#EB5757] border-x border-y border-[#EB5757]"
                onClick={(toggleConfirmCodal, closeConfirmModal)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVideoPopup;
