import React from "react";
import ReactPlayer from "react-player";

class VideoPlayer extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this.removeBackground();
  }

  componentDidUpdate(prevProps, prevState) {
    this.removeBackground();
    this.updateSubtitleStyle(this.props.styles);
    this.calculateAndSetSubtitlePosition(this.props.styles.position.value);
  }

  updateSubtitleStyle(style) {
    const css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = `video::cue {
      opacity: 1;
      background-color: transparent;
      color: ${style.color};
      font-size: ${style.fontSize}px;
      font-family: ${style.fontFamily};
    }`;
    document.body.appendChild(css);
  }

  removeBackground() {
    const css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = `video::cue { background-color: transparent; }`;
    document.body.appendChild(css);
  }

  calculateAndSetSubtitlePosition(position) {
    const video = document.getElementsByTagName("video");
    if (video) {
      Array.from(video[0].textTracks).forEach((track) => {
        if (track.mode === "showing") {
          Array.from(track.cues).forEach((cue) => {
            if (position === "top") {
              cue.line = 1;
            } else if (position === "center") {
              cue.line = 7;
            } else if (position === "bottom") {
              cue.line = 20;
            }
          });
          return true;
        }
      });
    }
  }

  componentWillUnmount() {}

  playback() {}
  render() {
    return (
      <figure
        className="rounded-xl shadow-lg pb-4"
        style={{
          width: "85%",
          height: "auto",
          margin: "auto",
          position: "relative",
        }}
        id="videoContainer"
        data-fullscreen="false"
      >
        <ReactPlayer
          url={this.props.src}
          className="react-player"
          id="v"
          controls
          playing
          width="100%"
          height="100%"
          onProgress={this.playback}
          config={{
            file: {
              attributes: {
                crossOrigin: "true",
                controlsList: "nofullscreen",
              },
              tracks: [
                {
                  kind: "captions",
                  src: this.props.subtitle_url,
                  srcLang: "en",
                  default: true,
                },
              ],
            },
          }}
        />
      </figure>
    );
  }
}
export default VideoPlayer;
