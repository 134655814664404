import { observable, action, decorate, makeObservable } from "mobx";
import LanguageStoreContent from "../api/data/language.json";

export class LanguageStore {
  parent = null;
  languageStore = [];

  constructor() {
    makeObservable(this, {
      languageStore: observable,
      loadLanguageStore: action,
    });
  }

  async loadLanguageStore() {
    return (this.languageStore = LanguageStoreContent);
  }
}
