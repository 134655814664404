import { createContext } from "react";
import { LanguageStore } from "./language-store";
import { RecentUploadsStore } from "./recent-uploads";
import { SubtitleEditorStore } from "./subtitlesEditor";
import { UploadVideoStore } from "./uploadVideo";

export class RootStore {
  Language;
  RecentUploads;
  SubtitleContent;
  UploadVideo;

  constructor() {
    this.Language = new LanguageStore(this);
    this.RecentUploads = new RecentUploadsStore(this);
    this.SubtitleContent = new SubtitleEditorStore(this);
    this.UploadVideo = new UploadVideoStore(this);
  }
}

export const StoreContext = createContext(new RootStore());
