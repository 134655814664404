export default {
  GET_JOB_STATUS: "/jobstatus",
  UPLOAD_VIDEO: "/upload",
  GENERATE_UPLOAD_URL: "/generate_upload_url",
  GERNERATE_SUBTITLES: "/generate_subtitles",
  EMDEDSUBTITLES: "/embedSubtitles",
  FEEDBACK: "/feedback",
  FETCH_ALL_VIDEOS:  "/fetch_all_videos",
  UPDATE_SUBTITLES: "/update_subtitles",
  FETCH_VIDEO_DETAILS: "/fetch_video_details",
  VIDEO_UPLOAD_SUCCESS: "/video_upload_success"
};
