import React from "react";
import Trash from "../../../app/assets/icons/trash.svg";

const SubTitle = ({
  paraText,
  from,
  to,
  handle_delete,
  index,
  bgColor,
  handleChange,
  changeStartTime,
  changeEndTime,
  handleFocus,
  timeStampErr,
  selectedIndex,
  endTimeStampErr,
  timeStampErrText,
  currentSelected,
  handleBlur
}) => {
  return (
    <div className="mb-5 h-30" key={index} onFocus={() => {
      handleFocus(index)
    }} onBlur={() => {
      handleBlur(index)
      }}>
      <div className="px-2 pt-2 max-w-lg bg-white hover:cursor-pointer rounded-lg border border-gray-300 hover:border hover:border-gray-400 hover:rounded-lg hover:border-pink-100 shadow-sm dark:bg-gray-800 dark:border-gray-700">
        <textarea
          rows={2}
          cols={34}
          placeholder="Type subtitle here..."
          onChange={handleChange}
          defaultValue={paraText}
          className={`text-sm w-full text-[#4F4F4F] dark:text-gray-400 focus:outline-0 resize-none ${
            bgColor ? "opacity-30 bg-gray-300" : "opacity-100"
          } `}
        ></textarea>
        <div className="py-2">
          <span className="inline-flex items-center py-2 px-3 text-xs font-small text-center text-gray-100 rounded-lg hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <div className="flex">
              <input
                type="text"
                name="name"
                className={`${
                  bgColor ? "opacity-30 bg-gray-300" : "opacity-100"
                } focus:outline-0 mx-1 w-10/12 ${
                  timeStampErr && selectedIndex === index
                    ? "text-[#fb4128]"
                    : ""
                }`}
                value={currentSelected?.index === index ? currentSelected.start_time : from}
                defaultValue={from}
                placeholder="enter start time"
                onChange={changeStartTime}
              />
              <span className="mx-2 w-1/12"> - </span>
              <input
                type="text"
                value={currentSelected?.index === index ? currentSelected.end_time : to}
                defaultValue={to}
                className={`${
                  bgColor ? "opacity-30 bg-gray-300" : "opacity-100"
                } focus:outline-0 mx-1 w-10/12 ${
                  endTimeStampErr && selectedIndex === index
                    ? "text-[#fb4128]"
                    : ""
                }`}
                onChange={changeEndTime}
                placeholder="enter end time"
                
              />
            </div>
            <img src={Trash} onClick={handle_delete} />
          </span>
          {timeStampErrText && selectedIndex === index ? (
            <p className="text-[#fb4128] text-center mx-auto w-3/4 text-xs">
              {timeStampErrText}
            </p>
          ) : (endTimeStampErr || timeStampErr) && selectedIndex === index ? (
            <p className="text-[#fb4128] text-center mx-auto w-3/4 text-xs">
              Invalid Time
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default SubTitle;
