import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../app/assets/images/cap-logo.svg";
import Unsplash from "../../../app/assets/images/unsplash.jpg";
import Close from "../../../app/assets/icons/close.svg";
import EditIcon from "../../../app/assets/icons/edit.svg";
import Trash from "../../../app/assets/icons/trash.svg";
import UploadVideoPopup from "../../components/uploadVideoPopup";
import { StoreContext } from "../../../app/stores/root-store";
import { useHistory } from "react-router-dom";
import "./styles.css";
import Feedback from "../../components/feedback";
import { Oval } from "react-loader-spinner";

const RecentUploads = () => {
  const store = useContext(StoreContext);

  const [localStateJsonData, setLocalStateJsonDataContent] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [language, setLanguage] = useState("");
  const [upload, setUpload] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [browseFile, setBrowseFile] = useState("");
  const [subtitleCOntent, setSubtitleContent] = useState("");
  const [video, setVideo] = useState("");
  const [videoId, setVideoID] = useState("");
  const [progress, setProgress] = useState(0);
  const [err, setErr] = useState("");
  const [subtitleErr, setSubtitleErr] = useState("");
  const [recentUploads, setRecentUploads] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState(false);

  let userID = localStorage.getItem("user_id");

  useEffect(() => {
    store.RecentUploads.loadRecentUploads()
      .catch(() => {})
      .finally(() => {
        setLocalStateJsonDataContent(store);
      });
    store.RecentUploads.getRecentUploadedVideos(userID).then((res) => {
      setRecentUploads(res?.data);
    });
  }, []);

  const history = useHistory();

  const handleModal = () => {
    setToggleModal(false);
    setConfirmModal(false);
    setBrowseFile("");
    setUpload("");
    setProgress(0);
    window.location.reload();
  };

  const handleBrowse = (e) => {
    setBrowseFile(e.target.files[0]);
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgress(percent);
      },
    };
    if (!e.target.files[0]) {
      setErr("Please browse the file!");
    } else if (e.target.files[0]?.size > 1024 * 1024 * 100) {
      setErr("Video file should be less than 100 MB");
    } else if (
      e.target.files[0] &&
      e.target.files[0]?.size < 1024 * 1024 * 101
    ) 
    {
      setErr("");
      const video_file = e.target.files[0]
      const config = {
        headers: {
          'content-type': video_file.type,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setProgress(percent);
        },
      };
      store.UploadVideo.upload_video(video_file,config).then((response) => {
        if (response?.status === 200) {
          setVideo(response?.data);
          }
        else {
          setErr("Something went wrong");
        }
      })
    }
  };
  const handleUploadedVideo = () => {
    // alert("hey");
  };

  const handleDelete = () => {
    setUpload("");
    setBrowseFile("");
    window.location.reload();
  };

  const handleSubtitles = () => {
    if (!upload && !language) {
      setSubtitleErr("Please upload video and select language!");
    } else {
      setSubtitleErr("");
      setLoaderVisible(true);
      store.UploadVideo.Generate_subtitles(
        video?.user_id,
        video?.video_id,
        language?.value
      ).then((response) => {
        setSubtitleContent(response?.data);
        if (response?.status === 200) {
          setLoaderVisible(false);
          setToggleModal(false);
          setConfirmModal(false);
          setVideoID(response?.data?.video_id);
          history.push("./recent-uploads");
          setBrowseFile("");
          setUpload("");
          setProgress(0); 
          store.RecentUploads.getRecentUploadedVideos(userID).then((res) => {
            setRecentUploads(res?.data);
          });
        }
      });
    }
  };

  const handleVidoEdit = (item) => {
    history.push({
      pathname: "/subtitle-editor"});
      localStorage.setItem('videoData', JSON.stringify(item))
  };

  useEffect(() => {
    let svg = document.querySelector('[data-testid="oval-svg"]');
    svg?.setAttribute('viewBox', '-22.5 -22.5 45 50')
  },[true])

  return (
    <div
      className={`${
        toggleModal || feedbackModal ? "bg-gray-100" : ""
      } h-screen relative`}
    >
      <div className="container mx-auto">
        <div className="flex justify-between items-center h-24">
          <Link to="/">
            <img src={Logo} />
          </Link>
          <button
            className={`px-7 py-2 rounded-lg bg-dark text-[#fff] border-dark-200 ${
              feedbackModal || toggleModal ? "opacity-20" : "opacity-100"
            }`}
            onClick={() => setToggleModal(true)}
          >
            Upload Video
          </button>
        </div>
        <div className="pt-10">
          <p className="py-8 text-[#1E262C] text-xl">Recently Uploaded</p>
          <div className="flex flex-wrap justify-start">
           {!recentUploads.length &&
           <div className="flex justify-center items-center w-full my-20">
             <div className="flex flex-col items-center">
             <Oval
              color="#1E262C"
              secondaryColor="#DBDBDB"
              height={60}
              width={70}
              strokeWidth={5}
              visible={true}
            />
             </div>
           </div>}
            {recentUploads?.map((item) => {
              return (
                <div
                  key={item?.video_id}
                  className={`relative videoWrapper h-64 w-1/4 hover:rounded-xl my-2 mx-3 ${
                    feedbackModal || toggleModal ? "opacity-20" : "opacity-100"
                  }`}
                  onClick={handleUploadedVideo}
                >
                  <video
                    className="p-0 rounded-xl rounded-xl h-full w-full object-fill "
                    poster={
                      item?.thumbnail_url ? item?.thumbnail_url : Unsplash
                    }
                    src={item?.video_url}
                    // controls
                  />
                  <div className="relative h-full rounded-lg w-full h-full edit-video overflow-hidden">
                    <div
                      className="flex justify-center items-center h-4/5"
                      onClick={() => handleVidoEdit(item)}
                    >
                      <img src={EditIcon} />
                      <button
                        className="text-[#fff] ml-1"
                        link="/subtitle-editor"
                      >
                        Edit Video
                      </button>
                    </div>
                    <div className="bg-[#000] flex justify-between items-center h-14 px-3">
                      <p className="text-[#fff] w-26 truncate hover:text-clip">
                        {item?.video_url}
                      </p>
                      <img src={Trash} className="w-8 h-8" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button
          className="my-10 float-right text-[#0056b3]"
          onClick={() => setFeedbackModal(true)}
        >
          Got feedback?
        </button>
        {/* popup Modal */}
        <div
          id="authentication-modal"
          className={`${
            feedbackModal == true ? "visible" : "hidden"
          } overflow-hidden fixed left-52 top-4 z-50 flex justify-center items-center h-modal md:h-full md:inset-0`}
        >
          <div
            className={`flex justify-center items-center bg-[#fff] rounded-lg`}
          >
            <div
              className={`relative px-4 w-full max-w-3xl py-9 md:h-auto mx-auto rounded-lg shadow-xl dark:bg-gray-700 w-96`}
            >
              <div className="flex justify-end items-center p-2">
                <p className="text-[#1E262C] text-lg">Leave a feedback</p>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={() => setFeedbackModal(false)}
                >
                  <img src={Close} />
                </button>
              </div>
              <div>
                <Feedback />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* popup Modal */}
      <div
        id="authentication-modal"
        className={`${
          toggleModal == true ? "visible" : "hidden"
        } overflow-hidden fixed left-52 top-4 z-50 flex justify-center items-center h-modal md:h-full md:inset-0`}
      >
        <UploadVideoPopup
          toggleConfirmCodal={() => setConfirmModal(true)}
          confirmModal={confirmModal}
          uploadedVideo={video}
          language={
            localStateJsonData?.RecentUploads?.recentUploads?.languageOpt
          }
          defaultValue={language?.value}
          handleBrowse={handleBrowse}
          browseFile={browseFile}
          closeConfirmModal={handleModal}
          generateSubtitles={handleSubtitles}
          loader={loaderVisible}
          handleLangChange={(value) => setLanguage(value)}
          progress={progress}
          handleDelete={handleDelete}
          error={err}
          subtitleErr={subtitleErr}
          videoThumnil={video?.thumbnail_url}
        />
      </div>
    </div>
  );
};
export default RecentUploads;
