import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { routes } from "./routes";
import "./app/common/global.css";

function App() {
  const [all_routes, setRoutes] = useState(routes);
  return (
    <div>
        <BrowserRouter>
          <Switch>
            {all_routes.map((route, index) => (
              <Route
                path={route.path}
                exact={route.exact}
                component={route.component}
                key={index}
              />
            ))}
          </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
