import React, { useState } from "react";
import Select from "react-select";
import Middle from "../../../app/assets/icons/middle.svg";
import Top from "../../../app/assets/icons/top.svg";
import Bottom from "../../../app/assets/icons/bottom.svg";

const Editor = ({
  fontFamilyToggle,
  handleFontSize,
  handlePosition,
  handleColorChange,
  fontSize,
  feedback,
  fontFamilyOpt
}) => {

  const positionsOpt = [
    { value: "top", label: <img src={Top} /> },
    { value: "center", label: <img src={Middle} /> },
    { value: "bottom", label: <img src={Bottom} /> },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 20,
    }),
    control: () => ({
      border: "1px solid #DBDBDB",
      borderRadius: "8px",
      display: "flex",
      width: 300,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const defaultOption = fontFamilyOpt[0];
  const defaultPosition = positionsOpt[2]
  return (
    <div className="flex md:justify-around justify-start xl:flex-nowrap sm:flex-wrap py-4 shadow mx-5 rounded-lg">
      <div className="px-4 py-2 border-r border-gray-300">
        <Select
          className="flex justify-between outline-0 w-50 max-w-lg"
          defaultValue={defaultOption}
          onChange={fontFamilyToggle}
          options={fontFamilyOpt}
          placeholder="Alata"
          styles={customStyles}
        />
      </div>
      <div className={`mx-2 py-2 border-r border-gray-300 ${feedback ? "opacity-10" : "opacity-100"}`}>
        <input
          type="number"
          name="name"
          className="w-16 px-2 mx-4 py-1 rounded-lg border-x border-y border-gray-300 outline-0"
          value={fontSize}
          min="6"
          onChange={handleFontSize}
        />
      </div>
      <div className="px-2 py-1 border-r border-gray-300 hover-cursor">
        <button className="mx-1 p-2 flex flex-col items-center">
          <input
            type="color"
            className="h-5 w-10 border-0 hover-cursor"
            name="color"
            defaultValue="#ffffff"
            onChange={handleColorChange}
          />
        </button>
      </div>
      <div className="mx-4 py-2">
        <Select
          // closeMenuOnSelect={false}
          className="flex justify-between outline-0 max-w-lg"
          defaultValue={defaultPosition}
          onChange={handlePosition}
          options={positionsOpt}
          placeholder="Position"
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default Editor;
